.homeContainer {
  text-align: center;
  height: 100%;

  .canvasContainer {
    background-color: #00000073;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  .logoContainer img {
    max-width: 50%;
    height: auto;
    animation: glitch 3s infinite steps(1);
  }

  @keyframes glitch {
    0%,
    100% {
      background: url('../images/ct_logo.png');
      background-size: cover;
    }
    90.5% {
      background: url('../images/glitch2.png');
      background-size: cover;
    }
    95% {
      background: url('../images/glitch3.png');
      background-size: cover;
    }
    97.5% {
      background: url('../images/glitch4.png');
      background-size: cover;
    }
  }

  .mainTitle,
  .dateTitle,
  .timeContainer .timeText {
    font-family: 'Unifont';
    color: rgb(0, 217, 255);
    margin: 20px 0;
    text-shadow:
      0 0 5px rgba(0, 217, 255, 0.8),
      0 0 10px rgba(0, 217, 255, 0.7);
  }

  .mainTitle,
  .dateTitle {
    font-size: 40px;
  }

  .timeContainer .timeText {
    font-size: 30px;
    @media (max-width: 600px) {
      font-size: 1em;
    }
  }

  .signupButton {
    width: 300px;
    padding: 10px 20px;
    font-size: 20px;
    margin-top: 20px;
    background-color: #00ccff1e;
    font-family: 'Unifont';
    font-weight: 900;
    color: rgb(0, 217, 255);
    border: 1px solid rgb(0, 217, 255);

    @media (max-width: 600px) {
      font-size: 16px;
      padding: 8px 15px;
    }
  }
}
