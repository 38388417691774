// Colores
$color-fade-background: #00000073;
$color-matrix-text: rgb(0, 217, 255);

// Fuentes
$Unifont: 'Unifont';

// Sombras de texto
$neon-matrix-text:
  0 0 5px rgba(0, 217, 255, 0.8),
  0 0 10px rgba(0, 217, 255, 0.7);

// Medidas responsivas
$ancho-movil: 600px;
