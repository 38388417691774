@import '../variables';

.adressContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000073;
  .tittleContainer {
    width: 70%;
    height: auto;
    text-shadow:
      0 0 5px rgba(0, 217, 255, 0.8),
      0 0 10px rgba(0, 217, 255, 0.7);
    margin-bottom: 10px;
    @media (max-width: 800px) {
      width: 90%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
    .tittle {
      color: white;
      font-family: 'Unifont';
      font-size: 2em;
      font-weight: 900;
      color: rgb(0, 217, 255);
      border-bottom: 2px solid rgb(0, 217, 255);

      @media (max-width: 800px) {
        width: 90%;
      }
      @media (max-width: 600px) {
        font-size: 2em;
      }
    }
  }
  .description {
    color: white;
    font-family: 'Unifont';
    font-size: 1em;
    font-weight: 900;
    color: rgb(0, 217, 255);
    margin-bottom: 20px;
    text-shadow: $neon-matrix-text;
    @media (max-width: 600px) {
      width: 90%;
      font-size: 0.8em;
    }
  }
  .mapContainer {
    width: 70%;
    height: 450px;
    border-radius: 20px;
    overflow: hidden;
    @media (max-width: 600px) {
      width: 90%;
      height: 60%;
      font-size: 0.5em;
    }
  }
}
