$color-principal: rgb(0, 217, 255);
$color-secundario: rgb(85, 242, 253);
$fondo-par: black;
$fondo-impar: #00ccff1e;
$fuente-principal: 'Unifont';

.tableContainer {
  max-width: 70%;
  max-height: 75%;
  border: 1px solid $color-principal;
  overflow-x: auto;
  @media (max-width: 600px) {
    max-width: 90%;
    max-height: 100%;
    border: 2px solid $color-principal;
  }
  // Personalización de la barra de desplazamiento
  &::-webkit-scrollbar {
    width: 5px;
    background-color: BLACK;
    @media (max-width: 600px) {
      display: none;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-principal;
    border-radius: 5px;
    border: 1px solid $color-principal;
  }
}

.table {
  overflow-y: hidden;
  min-width: 700px;
  background-color: $fondo-par;
  .tableHead {
    background-color: rgba($color-principal, 0.185);
    .tableRow {
      border: 1px solid $color-principal;
      color: $color-principal;
      text-shadow:
        0 0 5px rgba($color-principal, 0.8),
        0 0 10px rgba($color-principal, 0.7);
      font-family: $fuente-principal;
      font-weight: 900;
      font-size: 1.6em;
      @media (max-width: 600px) {
        font-size: 0.8em;
      }
    }
  }
  .tableBody {
    .tableRow {
      border: 1px solid $color-principal;
      color: $color-principal;
      font-family: $fuente-principal;
      font-weight: 900;
      &:nth-child(odd) {
        background-color: $fondo-impar;
      }
      &:nth-child(even) {
        background-color: $fondo-par;
      }
    }
    .tableCell {
      font-family: $fuente-principal;
      font-size: 1.4em;
      color: $color-secundario;
      border: 1px solid $color-principal;
      @media (max-width: 600px) {
        font-size: 0.8em;
        white-space: nowrap;
      }
      .event {
        display: flex;
        .nameBlur {
          margin-left: 10px;
          font-weight: 900;
          font-size: 1.1em;
          font-family: $fuente-principal;
          color: $color-principal;
          text-shadow:
            0 0 5px rgba($color-principal, 0.8),
            0 0 10px rgba($color-principal, 0.7);
          @media (max-width: 600px) {
            font-size: 0.9em;
          }
        }
      }
    }
  }
}
