.footerContainer {
  background: black;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
  height: 50px;
  @media (max-width: 600px) {
    display: flex;
    height: 50px;
    flex-direction: row;
    justify-content: space-around;
  }
  .logo {
    width: auto;
    height: 40px;
    margin-left: 30px;
    @media (max-width: 600px) {
      width: auto;
      height: 20px;
    }
  }
  .reserved {
    display: flex;
    flex-direction: row;
    .reservedText {
      @media (max-width: 600px) {
        font-size: 0.6em;
      }
    }
  }
  .legalAdvise {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 85px;
    @media (max-width: 600px) {
      font-size: 0.6em;
      margin-right: 0px;
    }
  }
  .legalAdviseButton {
    color: rgb(110, 110, 110);
    margin-left: 10px;
  }
}
