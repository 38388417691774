$color-principal: rgb(0, 217, 255);
$color-secundario: rgb(85, 242, 253);
$fondo-par: black;
$fondo-impar: #00ccff1e;
$fuente-principal: 'Unifont';

.previousContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #00000085;

  // Estilos para el ButtonGroup o botones si es necesario
  .yearButtonGroup {
    font-size: 2em;
    margin-bottom: 30px;
    // Aquí puedes agregar estilos específicos para el grupo de botones si es necesario
    .yearButton {
      color: rgb(0, 217, 255); // Ejemplo de estilo, ajusta según necesites
      border-color: rgba(0, 217, 255, 0.342);
      &:hover {
        background-color: rgba(
          0,
          217,
          255,
          0.2
        ); // Ejemplo de estilo para hover
      }
      &.active {
        text-shadow:
          0 0 5px rgba($color-principal, 0.8),
          0 0 10px rgba($color-principal, 0.7);
        border-color: rgb(
          0,
          217,
          255
        ); // Estilo para el botón activo, ajusta según la lógica de selección
      }
    }
  }
}
