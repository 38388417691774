@import '../variables';

.aboutContainer {
  background-color: #00000073;
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: $Unifont;

  .titleContainer {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .badgeLogoAbout {
      width: 180px;
      height: 180px;
      @media (max-width: 600px) {
        width: 100px;
        height: 100px;
      }
    }
    .aboutTitle {
      width: 100%;
      font-size: 4.5em;
      font-family: $Unifont;
      color: rgb(0, 217, 255);
      text-shadow: $neon-matrix-text;
      @media (max-width: 600px) {
        font-size: 2em;
        margin-bottom: 0px;
      }
    }
  }

  .paragraphContainer {
    display: flex;
    justify-content: center;
    width: 95%;
    overflow: hidden;
    font-family: $Unifont;
    text-shadow: $neon-matrix-text;
  }

  .aboutParagraph {
    text-align: left;
    color: $color-matrix-text;
    width: 100%;
    font-size: 25px;
    margin-top: 20px;
    font-family: $Unifont;
    @media (max-width: 1080px) {
      font-size: 1em;
      margin-bottom: 0px;
    }
    @media (max-width: 800px) {
      font-size: 1em;
      margin-bottom: 0px;
    }
    @media (max-width: 600px) {
      font-size: 1em;
    }
  }
}
