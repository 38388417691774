@import '../variables';

.navbar-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.link-wrapper {
  margin-bottom: 20px;
}
.badgeLogoContainer {
  display: flex;
  .badgeLogo {
    width: 50px;
    height: 50px;
    @media (max-width: 600px) {
      display: none;
    }
  }
}

.menuItems {
  display: flex;
}

.MuiButton-root {
  font-family: 'Unifont' !important;
  font-size: 1em !important;
  color: $color-matrix-text;
  @media (max-width: 1000px) {
    font-size: 0.8em !important;
  }
  @media (max-width: 800px) {
    font-size: 0.7em !important;
  }
}

.languageButton {
  text-shadow: $neon-matrix-text;
}
