.scroll-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: calc(100vh - 65px);
  > div {
    scroll-snap-align: start;
    height: calc(100vh - 65px);
  }

  .home-container {
    background: linear-gradient(rgba(0, 0, 0, 0.37), rgba(0, 0, 0, 0.329));
    /* url('./images/robot2.png') no-repeat center center fixed; */
  }
}
