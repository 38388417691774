.programContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #00000085;

  .tittleContainer {
    width: 70%;
    height: auto;
    border-bottom: 2px solid rgb(0, 217, 255);
    text-shadow:
      0 0 5px rgba(0, 217, 255, 0.8),
      0 0 10px rgba(0, 217, 255, 0.7);
    margin-bottom: 20px;
    @media (max-width: 600px) {
      width: 90%;
    }
    .tittle {
      color: white;
      font-family: 'Unifont';
      font-size: 2em;
      font-weight: 900;
      color: rgb(0, 217, 255);
      @media (max-width: 1080px) {
        font-size: 1.3em;
        margin-bottom: 0px;
      }
      @media (max-width: 800px) {
        font-size: 1em;
        margin-bottom: 0px;
      }
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }
}
